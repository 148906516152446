<template>
  <v-container class="pa-0">
    <v-row class="ma-0 pa-0">
      <v-col align-self="start" xl="3" lg="4" sm="12">
        <v-card outlined>
          <v-row class="ml-2 mr-0 my-1 pa-2">
            <span
              class="grey lighten-3 py-1 px-4 text-uppercase text-body-2"
              v-if="selectedUser && isAdmin"
            >
              {{ $ml.get("admin") }}
            </span>
          </v-row>
          <v-skeleton-loader
            v-if="!selectedUser"
            type="list-item-three-line"
          ></v-skeleton-loader>
          <div v-else class="pt-0 my-0">
            <v-card-actions class="align-start pl-4 py-0 my-0">
              <v-avatar :size="50" max-width="50">
                <v-img contain :src="userPhoto" />
              </v-avatar>
              <div class="py-0 ma-0 px-4">
                <v-card-title
                  class="font-weight-medium pa-0 ma-0 mb-4 text-truncate"
                >
                  {{ userName }}
                </v-card-title>
                <v-card-subtitle class="px-0 text-wrap">
                  {{ userEmail }}
                </v-card-subtitle>
                <div
                  :class="`${
                    isActive ? 'success--text' : 'error--text'
                  } text-body-2 font-weight-medium my-0`"
                >
                  {{ isActive ? $ml.get("active") : $ml.get("disabled") }}
                </div>
                <v-card-subtitle :class="'caption px-0 pt-2 pb-0 ma-0'">
                  {{
                    lastLogin
                      ? `${$ml.get("last_login")}: ${formatDateAndHour(
                          lastLogin
                        )}`
                      : "-"
                  }}
                </v-card-subtitle>
                <v-card-subtitle :class="'caption px-0 pt-2 pb-4 ma-0'">
                  {{ $ml.get("updated_password_at") }}: {{ updatedPasswordAt }}
                </v-card-subtitle>
                <!--
                <v-card-subtitle :class="'caption px-0 py-2 ma-0'">
                  {{ $ml.get("current_session_state") }}:
                  <span v-if="userLogged === undefined">-</span>
                  <span
                    v-else
                    :class="`${
                      userLogged ? 'success--text' : 'error--text'
                    } text-body-2 font-weight-medium my-0`"
                    >{{ userLogged ? "online" : "offline" }}</span
                  >
                </v-card-subtitle>
                -->
              </div>
            </v-card-actions>
            <div
              v-if="isOnVacation || isSettedVacation"
              class="pb-4 ma-0 yellow lighten-5 text-body-2"
            >
              <v-divider></v-divider>
              <strong class="d-flex align-center py-2 px-4">
                {{
                  isOnVacation
                    ? $ml.get("vacation_period")
                    : $ml.get("scheduled_vacation")
                }}
                <v-icon v-if="isOnVacation" right color="accent">
                  mdi-umbrella-beach
                </v-icon>
              </strong>
              <span class="px-4" v-html="vacationDate"> </span>
            </div>
          </div>
          <v-divider v-if="userOptions.length > 0" />
          <v-list class="py-0">
            <v-list-item-group color="grey darken-1">
              <v-list-item
                v-for="(item, i) in userOptions"
                :key="i"
                class="text-uppercase font-weight-medium"
                :disabled="item.disabled"
                @click="execute(item.action)"
              >
                <v-list-item-icon class="mr-5">
                  <v-icon v-text="item.icon" color="grey darken-1"></v-icon>
                </v-list-item-icon>
                <v-list-item-content>
                  <v-list-item-title
                    :class="`${
                      $vuetify.theme.dark ? '' : 'grey--text text--darken-1'
                    }`"
                    v-text="$ml.get(item.title_key)"
                  ></v-list-item-title>
                </v-list-item-content>
              </v-list-item>
            </v-list-item-group>
          </v-list>
        </v-card>
      </v-col>

      <v-col xl="9" lg="8" sm="12">
        <v-card outlined class="mb-4" :loading="loadingUser || loadingGroups">
          <v-card-title>
            {{ $ml.get("groups") }}
          </v-card-title>
          <v-card-text>
            <v-row>
              <v-col :cols="userGroupsDisabledNumber ? '8' : '12'">
                <v-card-subtitle
                  v-if="userGroups.length > 0"
                  v-text="
                    `${$ml.get('participant_of')} ${userGroupsNumber}  ${$ml
                      .get('groups')
                      .toLowerCase()} ${$ml.get('actives').toLowerCase()}`
                  "
                  class="ma-0 pa-0 mb-3 font-weight-medium"
                />
                <v-card-subtitle class="ma-0 pa-0 mb-2" v-else>{{
                  $ml.get("no_group")
                }}</v-card-subtitle>
                <p class="mx-0 pb-0" v-if="userGroups">
                  <v-chip
                    small
                    v-for="(group, i) in userGroups"
                    :key="i"
                    :to="{ name: 'Group', params: { key: group.key } }"
                    class="mr-2 mb-1"
                  >
                    <v-icon :color="group.color" size="18" left>
                      mdi-circle
                    </v-icon>
                    {{ group.name }}
                  </v-chip>
                </p>
              </v-col>
              <v-col cols="4" v-if="userGroupsDisabledNumber">
                <v-card-subtitle class="ma-0 pa-0 mb-3 font-weight-medium">
                  <span
                    v-text="
                      `${userGroupsDisabledNumber}  ${$ml
                        .get('group')
                        .toLowerCase()} ${$ml.get('disabled').toLowerCase()}`
                    "
                  ></span>
                  <Tooltip
                    color="grey darken-1"
                    :text="`
                ${$ml.get('groups_disabled')}`"
                    icon="mdi-information"
                  />
                </v-card-subtitle>
                <p class="mx-0 pb-0" v-if="userGroupsDisabled">
                  <v-chip
                    small
                    v-for="(group, i) in userGroupsDisabled"
                    :key="i"
                    :to="{ name: 'Group', params: { key: group.key } }"
                    class="mr-2 mb-1"
                  >
                    {{ group.name }}
                  </v-chip>
                </p>
              </v-col>
            </v-row>
          </v-card-text>
        </v-card>
        <v-card outlined class="mb-4" :loading="loadingUser || loadingGroups">
          <v-card-title>
            <span>
              {{ $ml.get("time_settings") }}
            </span>
            <v-spacer v-if="$vuetify.breakpoint.mdAndUp" />
            <v-select
              class="mb-1 mr-4"
              :disabled="loadingUser || events.length === 0 || loadingGroups"
              v-model="selectedViewType"
              :items="viewRules"
              dense
              outlined
              :multiple="false"
              style="max-width: 200px; height: 35px"
            >
              <template v-slot:selection="{ item }">
                {{ $ml.get(item.text) }}
              </template>
              <template v-slot:item="{ item }">
                {{ $ml.get(item.text) }}
              </template>
              <template v-slot:prepend-inner>
                <v-icon
                  v-text="
                    selectedViewType === 'week'
                      ? 'mdi-calendar-week'
                      : 'mdi-calendar-month'
                  "
                  class="mr-2"
                />
              </template>
            </v-select>
            <v-spacer v-if="$vuetify.breakpoint.smAndDown" />
          </v-card-title>

          <v-card-text
            class="mt-4 text-center"
            v-if="loadingUser || loadingGroups"
          >
            <v-progress-circular
              indeterminate
              color="secondary"
              class="mx-auto"
              size="60"
              width="4"
            />
          </v-card-text>

          <v-card-text class="mt-4 text-center" v-else>
            <v-calendar
              :first-interval="0"
              :interval-minutes="60"
              :interval-count="24"
              ref="calendar"
              :now="today"
              :value="today"
              :events="events"
              color="primary"
              :type="selectedViewType"
              style="border: 0px; height: 600px"
              class="overflow-y-auto calendar"
            >
              <template v-slot:event="{ event }">
                <div class="font-weight-medium small-text mx-1 text-center">
                  {{
                    event.type === "business_hours"
                      ? $ml.get("business_hours")
                      : `${$ml.get("holiday")}/${$ml
                          .get("special_date")
                          .toLowerCase()}`
                  }}
                </div>
                <div class="mx-1 small-text text-center">
                  {{ formatHourPeriod(event.start, event.end) }}
                </div>
              </template>
            </v-calendar>
          </v-card-text>
        </v-card>
        <ReportsLoginLogout
          v-if="!loadingUser"
          :userEmail="userEmail"
          :companyView="false"
          class="my-3"
        />
        <ReportsUsers
          v-if="!loadingUser"
          :userEmail="userEmail"
          :companyView="false"
          class="my-3"
        />
        <ReportsGroups
          v-if="!loadingUser"
          :userEmail="userEmail"
          :companyView="true"
          class="my-3"
        />
        <ReportsAccessTime
          v-if="!loadingUser"
          :userEmail="userEmail"
          :companyView="false"
          class="my-3"
        />
        <ReportsUserPermissions
          v-if="!loadingUser"
          :userEmail="userEmail"
          :companyView="false"
          class="my-3"
        />
        <ReportsCustomizeLogin
          v-if="!loadingUser"
          :userEmail="userEmail"
          :companyView="false"
          class="my-3"
        />
      </v-col>
    </v-row>
    <AddOrRemoveUserShift
      :show="addUserGroup"
      @close="addUserGroup = false"
      @update="events = setEvents()"
      :isRemovingFromShift="isRemovingFromShift"
    />

    <ChangeUserPassword
      v-if="selectedUser"
      :show="requestUserPasswordChange"
      :user="selectedUser"
      :user-key="selectedUser.key"
      @close="requestUserPasswordChange = false"
    />

    <Vacation
      v-if="selectedUser"
      :user="selectedUser"
      :show="vacationDialog"
      @close="closeVacationDialog"
    />

    <SingleAccessDialog
      v-if="selectedUser"
      :show="singleAccessDialog"
      :user="selectedUser"
      @update="setSelectedUser({ ...selectedUser, ...$event })"
      @close="singleAccessDialog = false"
    />

    <ConfirmationDialog
      :target="actionName ? $ml.get(actionName) : ''"
      :show="showConfirmDialog"
      :loading="loading"
      @close="showConfirmDialog = false"
      @ok="okConfirmDialog"
      action-text=""
      hideReversibleText
    >
    </ConfirmationDialog>
  </v-container>
</template>
<script>
import { mapActions, mapGetters, mapMutations } from "vuex";
import AddOrRemoveUserShift from "@/components/users/AddOrRemoveUserShift";
import ChangeUserPassword from "@/components/users/ChangeUserPassword.vue";
import Vacation from "@/components/users/Vacation";
import SingleAccessDialog from "@/components/users/SingleAccessDialog.vue";
import * as moment from "moment";
import Tooltip from "@/components/base/Tooltip.vue";
import ReportsLoginLogout from "@/views/logs/ReportsLoginLogout.vue";
import ReportsUsers from "@/views/logs/ReportsUsers.vue";
import ReportsGroups from "@/views/logs/ReportsGroups.vue";
import ReportsAccessTime from "@/views/logs/ReportsAccessTime.vue";
import ReportsUserPermissions from "@/views/logs/ReportsUserPermissions.vue";
import ReportsCustomizeLogin from "@/views/logs/ReportsCustomizeLogin.vue";
import {
  today,
  formatHourPeriod,
  formatStringHour,
  formatDate,
  formatDateForDatabase,
  getPreviousAndNextMonth,
  formatDateUTC,
  eventColors,
  formatDateAndHour,
  subtractMonths,
} from "@/helpers/utils";
export default {
  name: "User",
  components: {
    AddOrRemoveUserShift,
    ChangeUserPassword,
    Vacation,
    ReportsLoginLogout,
    ReportsUsers,
    ReportsGroups,
    ReportsAccessTime,
    SingleAccessDialog,
    Tooltip,
    ReportsUserPermissions,
    ReportsCustomizeLogin,
  },
  data() {
    return {
      eventColors,
      today,
      isRemovingFromShift: false,
      requestUserPasswordChange: false,
      addUserGroup: false,
      viewRules: [
        { text: "this_week", value: "week" },
        { text: "this_month", value: "month" },
        { text: "this_day", value: "day" },
      ],
      selectedViewType: "week",
      allEvents: [],
      vacationDialog: false,
      singleAccessDialog: false,
      showConfirmDialog: false,
      actionName: "",
      showUserLogs: false,
      screenWidth: window.innerWidth,
      loading: false,
      userLogged: undefined,
    };
  },

  computed: {
    ...mapGetters([
      "userDefaultImage",
      "selectedUser",
      "loadingUser",
      "loadingGroups",
      "groups",
    ]),

    userOptions() {
      return [
        {
          title_key: "add_user_to_group",
          icon: "mdi-account-plus",
          action: "add_user_to_group",
          show: true,
        },
        {
          title_key: "remove_user_from_a_group",
          icon: "mdi-account-minus",
          action: "remove_user_from_a_group",
          show: true,
        },
        {
          title_key: "request_password_change",
          icon: "mdi-lock-reset",
          action: "request_user_password_change",
          show: true,
        },
        {
          title_key: "change_password",
          icon: "mdi-lock",
          action: "change_password",
          show: true,
        },
        {
          title_key: "request_logout",
          icon: "mdi-logout-variant",
          action: "request_logout",
          show: true,
          disabled: !this.selectedUser.is_active,
        },
        {
          title_key: "grant_single_access",
          icon: "mdi-account-lock-open",
          action: "grant_single_access",
          show: !this.hasSingleAccess,
        },
        {
          title_key: "remove_single_access",
          icon: "mdi-account-lock",
          action: "remove_single_access",
          show: this.hasSingleAccess,
        },
        {
          title_key:
            this.isOnVacation || this.isSettedVacation
              ? "edit_vacation_period"
              : "set_user_vacation",
          icon: "mdi-airplane-clock",
          action: "set_user_vacation",
          show: true,
        },
        {
          title_key: "remove_user_vacation",
          icon: "mdi-airplane-minus",
          action: "remove_user_vacation",
          show: this.isOnVacation || this.isSettedVacation,
        },
        {
          title_key: "reports",
          icon: "mdi-poll",
          action: "reports",
          show: true,
          disabled: this.loadingUser,
        },
      ].filter((item) => item.show);
    },

    user() {
      return this.selectedUser;
    },

    userName() {
      return this.selectedUser.name || this.selectedUser.username;
    },

    userEmail() {
      return this.selectedUser.email;
    },

    userPhoto() {
      return this.user.photo || this.userDefaultImage;
    },

    lastLogin() {
      return this.selectedUser.last_login;
    },

    isActive() {
      return this.selectedUser.is_active;
    },

    email() {
      return this.selectedUser.email;
    },

    isAdmin() {
      return this.selectedUser.is_admin;
    },

    groupsKeys() {
      if (this.selectedUser) {
        return this.selectedUser.rule_group;
      } else {
        return [];
      }
    },

    userGroups() {
      if (this.selectedUser) {
        return this.groups.reduce((acc, group, index) => {
          if (group.is_active && this.groupsKeys.includes(group.key)) {
            group.color = this.eventColors[index];
            return acc.concat(group);
          }
          return acc;
        }, []);
      } else {
        return [];
      }
    },

    userGroupsDisabled() {
      if (this.selectedUser) {
        return this.groups.reduce((acc, group, index) => {
          if (!group.is_active && this.groupsKeys.includes(group.key)) {
            group.color = this.eventColors[index];
            return acc.concat(group);
          }
          return acc;
        }, []);
      } else {
        return [];
      }
    },

    userGroupsNumber() {
      return this.userGroups.length;
    },

    userGroupsDisabledNumber() {
      return this.userGroupsDisabled.length;
    },

    isOnVacation() {
      return this.selectedUser.is_on_vacation;
    },

    isSettedVacation() {
      return (
        !this.selectedUser.is_on_vacation &&
        moment(new Date()).isBefore(
          new Date(this.selectedUser.start_vacation_date)
        )
      );
    },

    hasSingleAccess() {
      return this.selectedUser.has_single_access;
    },

    vacationDate() {
      return `${this.$ml.get("from")} <strong> ${formatDateUTC(
        this.selectedUser.start_vacation_date
      )}</strong> ${this.$ml.get("until")} <strong>${formatDateUTC(
        this.selectedUser.end_vacation_date
      )}</strong>`;
    },

    hourRules() {
      return this.userGroups.flatMap((group) => {
        group.rules.flatMap((rule) => (rule.color = group.color));
        return group.rules;
      });
    },

    listOfSpecialDates() {
      return this.userGroups.flatMap((group) => {
        return group.list_special_dates ? group.list_special_dates : [];
      });
    },

    specialDates() {
      return this.listOfSpecialDates.reduce((acc, date) => {
        const _date = formatDateForDatabase(date, "DD/MM/YYYY");

        if (_date) {
          acc.push({
            type: "special_date",
            start: _date,
            end: _date,
            color: "info",
          });
        }
        return acc;
      }, []);
    },

    loginEvents() {
      let dates = getPreviousAndNextMonth();

      return dates.flatMap((item) => {
        let search = this.hourRules.filter(
          (hourRule) => hourRule.day === item.day_number
        );

        let dateToSearch = formatDate(item.date);
        let isSpecialDate = this.listOfSpecialDates.includes(dateToSearch);

        return search.reduce((acc, rule) => {
          if (!isSpecialDate) {
            acc.push({
              type: "business_hours",
              start: `${item.date} ${formatStringHour(rule.start_hour)}`,
              end: `${item.date} ${formatStringHour(rule.end_hour)}`,
              color: rule.color,
            });
          }
          return acc;
        }, []);
      });
    },
    currentUser() {
      return this.$route.params.id;
    },

    isSmallScreen() {
      return this.screenWidth < 512;
    },

    events: {
      get() {
        return this.allEvents;
      },
      set(new_value) {
        this.allEvents = new_value;
      },
    },

    updatedPasswordAt() {
      return formatDateAndHour(this.selectedUser.updated_password_at);
    },
  },
  watch: {
    loadingGroups() {
      if (!this.loadingGroups) {
        this.startLoadUser();
      }
    },
    async currentUser(newValue, oldValue) {
      if (newValue != oldValue) {
        await this.startLoadUser();
      }
    },
    isSmallScreen() {
      if (this.isSmallScreen) {
        this.selectedViewType = "day";
      }
      if (!this.isSmallScreen) {
        this.selectedViewType = "week";
      }
    },
  },
  methods: {
    formatHourPeriod,
    formatDateForDatabase,
    formatDateUTC,
    formatDateAndHour,

    ...mapActions([
      "forcePasswordChange",
      "getUser",
      "forceLogout",
      "setSnackbarDefault",
      "removeUserVacation",
    ]),
    ...mapMutations(["setLoadingUser", "setSelectedUser"]),

    async okConfirmDialog() {
      if (this.actionName == "request_logout") {
        this.setLoadingUser(true);
        await this.forceLogout(this.selectedUser.key).finally(() => {
          this.setLoadingUser(false);
          this.showConfirmDialog = false;
        });
      } else if (this.actionDialog === "request_user_password_change") {
        this.loading = true;
        await this.forcePasswordChange([this.selectedUser.email]).finally(
          () => {
            this.loading = false;
            this.showConfirmDialog = false;
          }
        );
      }
    },

    async execute(action) {
      if (action === "add_user_to_group") {
        this.isRemovingFromShift = false;
        this.addUserGroup = true;
      } else if (action === "request_logout") {
        this.actionName = action;
        this.showConfirmDialog = true;
      } else if (action === "request_user_password_change") {
        this.showConfirmDialog = true;
        this.actionDialog = action;
        this.actionName = "request_password_change";
      } else if (action === "change_password") {
        this.requestUserPasswordChange = true;
      } else if (action === "remove_user_from_a_group") {
        this.isRemovingFromShift = true;
        this.addUserGroup = true;
      } else if (action === "set_user_vacation") {
        this.vacationDialog = true;
      } else if (action === "remove_user_vacation") {
        this.setLoadingUser(true);
        this.removeUserVacation(this.selectedUser.key).finally(() => {
          this.setLoadingUser(false);
        });
      } else if (
        action === "grant_single_access" ||
        action === "remove_single_access"
      ) {
        this.singleAccessDialog = true;
      } else if (action === "reports") {
        this.showUserLogs = !this.showUserLogs;
        this.$vuetify.goTo("#user-logs");
      }
    },

    setEvents() {
      let userEvents = [];
      Array.prototype.push.apply(userEvents, this.loginEvents);
      Array.prototype.push.apply(userEvents, this.specialDates);
      return userEvents;
    },

    getUserSession() {
      const fromDate = subtractMonths(6);
      const url = `${process.env.VUE_APP_API_BASE_URL}/logs/challenge-errors/company?page_index=1&rows_per_page=1&email=${this.selectedUser.email}&from_date=${fromDate}`;

      this.$axios
        .get(url)
        .then(({ data }) => {
          if (data.logs.length) {
            const lastLog = data.logs[0];

            this.userLogged = lastLog.status.code === "USER_SUCCESS";
          } else {
            this.userLogged = false;
          }
        })
        .catch((error) => {
          console.error("getUserSession()", error);
        });
    },

    async startLoadUser() {
      this.userLogged = undefined;

      await this.getUser(this.$route.params.id).catch((response) => {
        console.error("vuex: getUser():", response);
        this.setSnackbarDefault({ message: "error_user_page", color: "error" });
        setTimeout(() => this.$router.push("/users"), 3000);
      });

      this.events = this.setEvents();
      this.setLoadingUser(false);
    },

    closeVacationDialog() {
      this.vacationDialog = false;
    },

    handleResize() {
      this.screenWidth = window.innerWidth;
    },
  },
  mounted() {
    this.selectedViewType = this.isSmallScreen ? "day" : "week";
    window.addEventListener("resize", this.handleResize);
  },
  beforeDestroy() {
    window.removeEventListener("resize", this.handleResize);
  },
  async beforeMount() {
    await this.startLoadUser();
  },
};
</script>
<style scoped>
.small-text {
  font-size: 10px !important;
}
.hover-icon button {
  visibility: hidden;
}
.hover-icon:hover button {
  visibility: visible;
}
</style>
